import { PriceBand, Seat } from '@/types'

import { create } from 'zustand'

// Define a type for the user object

// Define the store's state
type useEventState = {
  eventId: string | undefined
  eventStatus: string | undefined
  mapSeats: Seat[] | undefined
  priceBands: PriceBand[]
  selectedSeats: Seat[]
  searchingSeats: boolean
  setEventId: (eventId: string | undefined) => void
  setEventStatus: (bookingNumber: string | undefined) => void
  setPriceBands: (priceBands: PriceBand[]) => void
  setMapSeats: (mapSeats: Seat[] | undefined) => void
  setSelectedSeats: (selectedSeats: Seat[] | undefined) => void
  setSearchingSeats: (searchingSeats: boolean) => void
}

// Create the store
const useEventStore = create<useEventState>((set) => ({
  eventId: undefined,
  eventStatus: undefined,
  mapSeats: undefined,
  priceBands: [],
  selectedSeats: [],
  searchingSeats: false,
  setEventId: (eventId) => set({ eventId }),
  setEventStatus: (eventStatus) => set({ eventStatus }),
  setPriceBands: (priceBands) => set({ priceBands }),
  setMapSeats: (mapSeats) => set({ mapSeats }),
  setSelectedSeats: (selectedSeats) => set({ selectedSeats }),
  setSearchingSeats: (searchingSeats) => set({ searchingSeats }),
}))

export default useEventStore
