import {
  ActionType,
  ChannelAction,
  ContentType,
  ShareChannelAction,
} from '@/types/preshow'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CastIcon } from '@/assets/contentChannel/CastIcon'
import { DrinkIcon } from '@/assets/contentChannel/DrinkIcon'
import { MerchIcon } from '@/assets/contentChannel/MerchIcon'
import { ShareIcon } from '@/assets/contentChannel/ShareIcon'
import { useContentChannelStore } from '@/context/useContentChannelStore'
import useScreenSize from '@/hooks/useScreenSize'

enum ActionListItemName {
  cast = 'cast',
  bar = 'bar',
  merch = 'merch',
  share = 'share',
}

interface ActionBarProps {
  actions: ChannelAction
}

const ActionIcon = ({ icon }: { icon: string }) => {
  icon.toLocaleLowerCase()
  switch (icon) {
    case 'cast':
      return <CastIcon />
    case 'bar':
      return <DrinkIcon />
    case 'merch':
      return <MerchIcon />
    case 'share':
      return <ShareIcon />
    default:
      return <></>
  }
}

const ActionListItem = ({
  icon,
  text,
  url,
  variants,
  hideText,
  disabled,
}: {
  icon: string
  text: string
  url?: string
  variants: any
  hideText: boolean
  disabled: boolean
}) => {
  const navigate = useNavigate()
  const params = useParams()
  const { channelContent } = useContentChannelStore()

  const shareContent = async () => {
    const subject = await (
      channelContent.actions.find(
        (action) => action.action_type === ContentType.SHARE,
      ) as ShareChannelAction
    ).content.subject

    const body = await (
      channelContent.actions.find(
        (action) => action.action_type === ContentType.SHARE,
      ) as ShareChannelAction
    ).content.body

    navigator
      .share({
        title: subject,
        text: `${body}${` `}${window.location.href}`,
      })
      .then(() => console.log('Shared successfully!'))
      .catch((error) => console.error('Error sharing:', error))
  }

  const getItemLink = (text: ActionListItemName, url?: string) => {
    text.toLocaleLowerCase()
    switch (text) {
      case ActionListItemName.cast:
        return navigate(`/meet-the-cast/${params.id ?? ''}`)
      case ActionListItemName.bar:
        return window.open(url, '_blank')
      case ActionListItemName.share:
        return shareContent()
      case ActionListItemName.merch:
        return window.open(url, '_blank')
      default:
        return ''
    }
  }

  return (
    <div className={`${disabled && 'opacity-50'}`}>
      <motion.li
        className={`flex flex-col items-center gap-1`}
        onClick={() =>
          getItemLink(text.toLocaleLowerCase() as ActionListItemName, url)
        }
        variants={variants}
      >
        {disabled && !hideText && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: !hideText ? 1 : 0 }}
            exit={{ opacity: 0 }}
            className="absolute top-[-10px] bg-purple6/50 flex items-center justify-start rounded-md px-2 left-[4px]"
          >
            <p className="text-[10px] text-purple leading-4">{`Coming soon`}</p>
          </motion.div>
        )}
        <ActionIcon icon={icon} />
        <AnimatePresence>
          <motion.p
            initial={{ opacity: 1, height: 'auto' }}
            animate={
              hideText
                ? { opacity: 0, height: 0 }
                : { opacity: 1, height: 'auto' }
            }
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="overflow-hidden"
          >
            {text}
          </motion.p>
        </AnimatePresence>
      </motion.li>
    </div>
  )
}

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const itemVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
}

export const ActionBar = ({ actions }: ActionBarProps) => {
  const [hideText, setHideText] = useState(false)
  const screenSize = useScreenSize()
  const actionBarRef = useRef<HTMLDivElement>(null)
  const { channelContent } = useContentChannelStore()

  useEffect(() => {
    const handleScroll = () => {
      if (!actionBarRef.current) return

      const scrollPosition = window.scrollY + window.innerHeight
      const threshold = document.body.scrollHeight * 1.3

      if (scrollPosition >= threshold) {
        setHideText(true)
      } else {
        setHideText(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      id={`channel-action-bar`}
      ref={actionBarRef}
      className={`fixed z-[100] bottom-4 bg-purple1 rounded-full shadow-[4px_10px_15px_-3px_rgb(0_0_0_/_0.3),4px_4px_6px_0px_rgb(0_0_0_/_0.3)] ${screenSize.width > 1024 ? 'w-[33%]' : ' w-[95%] '} cursor-pointer`}
    >
      <AnimatePresence>
        <motion.ul
          className="w-full flex justify-evenly p-3 py-4 capitalize text-[12px]"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={containerVariants}
        >
          {channelContent.actions
            .filter((action) =>
              // filter out actions that are not set in actions types
              Object.values(ActionListItemName).includes(
                action.title.toLocaleLowerCase() as ActionListItemName,
              ),
            )
            // sort for enabled actions first
            .sort(
              (a: ActionType, b: ActionType) =>
                Number(!a.enabled) - Number(!b.enabled),
            )
            .map((action) => (
              <div
                className={`relative w-full`}
                key={action.title}
                id={`action-bar-item-${action.title.toLowerCase()}`}
              >
                <ActionListItem
                  icon={action.title.toLowerCase()}
                  text={action.title}
                  url={
                    (action.content as { external_link?: string })
                      .external_link ?? ''
                  }
                  variants={itemVariants}
                  hideText={hideText}
                  disabled={!action.enabled}
                />
              </div>
            ))}
        </motion.ul>
      </AnimatePresence>
    </div>
  )
}
