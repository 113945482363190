import axios from 'axios'

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const refreshToken = async (refreshToken: string) => {
  try {
    const response = await axios.post('auth/token', {
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    })
    const newToken = response.data.access_token
    localStorage.setItem('access_token', newToken)
    return newToken
  } catch (error) {
    console.error('Token refresh error:', error)
    throw error
  }
}

const executeApiRequest = async (
  endpoint: string,
  method: string,
  token: string | null,
  data?: any,
  headers = {},
) => {
  try {
    const response = await apiClient({
      method,
      url: endpoint,
      data,
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error: any) {
    if (error.response?.status === 401) {
      error.isTokenExpired = true
      console.log('Token expired, logging out...')
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      window.location.href = '/login'
    } else {
      return error
    }
  }
}

export const requestWithRetry = async (
  endpoint: string,
  method: string,
  data?: any,
  headers = {},
) => {
  let token = localStorage.getItem('access_token')
  const refresh_token = localStorage.getItem('refresh_token')

  try {
    const response = await Promise.race([
      executeApiRequest(endpoint, method, token, data, headers),
      new Promise(
        (_, reject) => setTimeout(() => reject({ timeout: true }), 15000), // 15 seconds timeout
      ),
    ])
    return response
  } catch (error: any) {
    console.log('Request error:', error)
    if (error.timeout) {
      console.error('Request timed out')
      return false
    }
    if (error.isTokenExpired) {
      console.log('Token expired, refreshing token...')
      try {
        token = await refreshToken(refresh_token as string)
        if (token) {
          localStorage.setItem('access_token', token) // Save the new token
          return await executeApiRequest(endpoint, method, token, data, headers) // Retry with new token
        }
      } catch (refreshError) {
        console.error('Error during token refresh:', refreshError)
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
      }
    }
  }
}
