import { SubmitHandler, useForm } from 'react-hook-form'
import {
  personalDetailsFormSchema,
  personalDetailsFormSchemaType,
} from '@/lib/validation-schemas/personalDetailsFormSchema'

import { ChevronRight } from 'lucide-react'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { getUniqueId } from '@/utils'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import useConditionalRender from '@/hooks/useConditionalRender'
import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'

export const PaymentDetails = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const [editPaymentInfo, setEditPaymentInfo] = useState<boolean>(false)
  const [addPaymentInfo, setAddPaymentInfo] = useState<boolean>(false)
  const [cardsRegistered, setCardsRegistered] = useState<
    {
      cardIssuer: string
      endingNumber: string
    }[]
  >([
    {
      cardIssuer: 'Visa',
      endingNumber: '1234',
    },
  ])

  const { handleSubmit } = useForm<personalDetailsFormSchemaType>({
    resolver: zodResolver(personalDetailsFormSchema),
  })

  const onSubmit: SubmitHandler<personalDetailsFormSchemaType> = (data) => {
    console.log('DATA SENT ->', data)
  }

  return (
    <DefaultLayout>
      {useConditionalRender(
        !editPaymentInfo && !addPaymentInfo,
        <RouteTransition>
          <div className="flex flex-col justify-start gap-4">
            <HeadlineBar
              title={`payment details`}
              goBack={() => callRouteWithDirection('/tickets', true, 3)}
            />
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white overflow-scroll flex flex-col gap-4 text-purple h-[90svh] p-4 py-6"
            autoComplete={'off'}
          >
            {!cardsRegistered && (
              <div className="flex gap-2 justify-center m-8">
                <p className="text-center text-grey italic">No cards added.</p>
              </div>
            )}

            {cardsRegistered.map((card, index) => {
              return (
                <div
                  key={getUniqueId()}
                  className="flex justify-between px-6 py-6 bg-purple7 rounded-[40px]"
                  onClick={() => setEditPaymentInfo(true)}
                >
                  <div className="flex gap-1 font-normal text-black">
                    <p>{card.cardIssuer + ` Card`}</p>
                    <p>{`ending with`}</p>
                    <p className="font-semibold">{card.endingNumber}</p>
                  </div>
                  <ChevronRight className="w-6 h-6 text-purple3" />
                </div>
              )
            })}

            {/* <Button
              variant="primaryDark"
              // disabled={
              //   newFirstName === user?.firstname &&
              //   newLastName === user?.lastname &&
              //   newEmail === user?.email &&
              //   newPhoneNumber === user?.phone &&
              //   newCountryCode.acronym === user?.countryCode
              // }
              className="w-full font-normal py-4 mt-4"
              onClick={() => setEditPaymentInfo(true)}
            >{`Edit`}</Button> */}

            <p
              className="relative text-center text-purple3"
              onClick={() => setAddPaymentInfo(true)}
            >
              {cardsRegistered.length ? `Add another card` : `Add a card`}
            </p>
          </form>
        </RouteTransition>,
      )}

      {/* {useConditionalRender(
        editPaymentInfo,
        <EditPaymentDetails setOpen={setEditPaymentInfo} />,
      )} */}

      {/* {useConditionalRender(
        addPaymentInfo,
        <AddPaymentDetails setOpen={setAddPaymentInfo} />,
      )} */}
    </DefaultLayout>
  )
}
