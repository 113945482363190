import { requestWithRetry } from '../axios'

export const getPlan = async (order_uid: string) => {
  try {
    return await requestWithRetry(
      `events/seats/plan?order_uid=${order_uid}`,
      `get`,
    ).catch((err) => {
      console.error('Error fetching plan', err)
      return err
    })
  } catch (error: any) {
    console.log(error, 'error fetching plan')
    return error.response?.data
  }
}
