import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import React, { ReactNode } from 'react'
import { transitionEasing, transitionEasingReverse } from '@/lib/global-vars'

import { Plus } from 'lucide-react'
import { motion } from 'framer-motion'
import useScreenSize from '@/hooks/useScreenSize'

type NotificationProps = {
  title: string
  content: ReactNode
  icon: ReactNode
  size?: 'small' | 'large'
  onClose?: () => void
}

export const Notification = ({
  title,
  content,
  icon,
  size,
  onClose,
}: NotificationProps) => {
  const screenSize = useScreenSize()

  const transition = {
    ease: transitionEasing,
    duration: 0.325,
  }
  const transitionOut = {
    ease: transitionEasingReverse,
    duration: 0.125,
  }

  return (
    <motion.div
      transition={transition}
      initial={{ opacity: 0, translateY: 100, filter: 'blur(10px)' }}
      animate={{ opacity: 1, translateY: 0, filter: 'blur(0)' }}
      exit={{
        opacity: 0,
        translateY: 100,
        filter: 'blur(5px)',
        transition: transitionOut,
      }}
      className={`${screenSize.width > 1280 ? 'mx-[33vw] w-[-webkit-fill-available] w-[-moz-available]' : 'w-full'} bottom-sheet fixed z-1 left-0 bottom-0`}
    >
      <div className="relative">
        <Alert
          className={`h-full mt-4 px-4 pt-4 pb-20 bg-purple2 border-none flex flex-col justify-center`}
        >
          {icon}
          <AlertTitle className="font-body font-bold text-sm">
            {title}
          </AlertTitle>
          <AlertDescription>{content}</AlertDescription>
        </Alert>
        <Plus
          width={20}
          height={20}
          onClick={onClose}
          role="button"
          className="z-10 absolute top-[8%] right-[1.25rem] rotate-45"
        />
      </div>
    </motion.div>
  )
}
