import React, { useEffect, useRef, useState } from 'react'

import { ExpandableText } from './ExpandableText'
import { PlayIcon } from '@/assets/PlayIcon'
import { UpSeatLogo } from '@/assets/upseat_logo'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'

interface GridVideoProps {
  url: string
  description: string
  videoResolution: {
    width: number
    height: number
  }
  gridVideosLoaded: boolean
  setGridVideosLoaded: (value: boolean) => void
  setCurrentEvent: (contentUrl: string) => void
}

export const GridVideo: React.FC<GridVideoProps> = ({
  url,
  description,
  setCurrentEvent,
  gridVideosLoaded,
  setGridVideosLoaded,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.controls = false
    }
  }, [videoRef])

  useEffect(() => {
    const video = videoRef.current

    if (!video) return

    let actionTimeout: NodeJS.Timeout

    const handlePlayPause = async () => {
      try {
        if (isPlaying) {
          // Cancel any scheduled pause action
          clearTimeout(actionTimeout)

          if (video.paused) {
            await video.play()
          }
        } else {
          // Schedule pause to prevent overlapping play/pause
          actionTimeout = setTimeout(() => {
            if (!video.paused) {
              video.pause()
            }
          }, 50) // Slight delay to allow play() to complete if triggered
        }
      } catch (error: any) {
        if (error.name !== 'AbortError') {
          console.log('Error playing video:', error)
        }
      }
    }

    handlePlayPause()

    // Cleanup timeout
    return () => clearTimeout(actionTimeout)
  }, [isPlaying])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsPlaying(entry.isIntersecting) // Update playing state based on visibility
        if (!entry.isIntersecting) {
          setIsMuted(true)
        }
      },
      { threshold: 1 },
    )

    const container = containerRef.current
    if (container) observer.observe(container)

    return () => {
      if (container) observer.unobserve(container)
      observer.disconnect()
    }
  }, [])

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime)
    }
  }

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration)
    }
  }

  return (
    <div
      ref={containerRef}
      className="relative w-full h-full flex justify-center items-center"
    >
      <video
        ref={videoRef}
        loop
        controls={false}
        muted={isMuted}
        playsInline={true}
        className={`w-[100vw] h-full max-h-[50vh] object-cover transition-opacity duration-500 ${
          gridVideosLoaded ? '' : 'h-0'
        }`}
        onLoadedMetadata={handleLoadedMetadata}
        onCanPlay={() => setGridVideosLoaded(true)}
        onCanPlayThrough={() => setGridVideosLoaded(true)}
        onTimeUpdate={handleTimeUpdate}
        onClick={() => setCurrentEvent(url)}
      >
        <source src={url} />
      </video>
      {!isPlaying && (
        <div className="absolute">
          <div
            className="[&>svg]:relative [&>svg]:left-[1px] cursor-pointer z-50 bg-white/50 rounded-full w-16 h-16 flex items-center justify-center"
            onClick={() => setIsPlaying(!isPlaying)}
          >
            <PlayIcon />
          </div>
        </div>
      )}
      <div
        className={`absolute bottom-[-1px] flex w-full items-center justify-between p-4 ${
          description &&
          'bg-[linear-gradient(to_top,rgba(0,0,0,.6)_80%,rgba(0,0,0,0)_100%)]'
        }`}
      >
        {description && (
          <div className="w-full pr-4">
            <ExpandableText
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              text={description}
              limit={60}
            />
          </div>
        )}
        <div
          className={`${
            isPlaying && !description && 'absolute right-0 bottom-0 p-4'
          }`}
        >
          <div
            className="cursor-pointer z-50 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
            onClick={() => setIsMuted(!isMuted)}
          >
            {isMuted ? <VolumeOff /> : <VolumeOn />}
          </div>
        </div>
      </div>
    </div>
  )
}
