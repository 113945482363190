import { Booking, Order } from '@/types/booking'
import React, { useEffect, useRef, useState } from 'react'
import { motion, useSpring } from 'framer-motion'

import { CardFlipper } from '../wallet/CardFlipper'
import { Indexes } from './useIndexes'
import { TicketCard } from './TicketCard'
import { TicketCardBackFace } from './TicketCardBackFace'
import { animated } from '@react-spring/web'
import { determineClasses } from '.'
import { useTicketsStore } from '@/context/useTicketsStore'

interface CardListProps {
  walletOrders: Order[]
  indexes: Indexes
  bind: any
  api: any
}

export const CardList: React.FC<CardListProps> = ({
  walletOrders,
  indexes,
  bind,
  api,
}) => {
  const { wallet } = useTicketsStore()
  const [rotateXaxis, setRotateXaxis] = useState(0)
  const [rotateYaxis, setRotateYaxis] = useState(0)
  const [isFlipped, setIsFlipped] = useState(false)
  const [isFlippedInactive, setIsFlippedInactive] = useState(true)
  const timeoutRef = useRef<number | null>(null)

  //Spring animation parameters
  const spring = {
    mass: 5,
    tension: 500,
    friction: 80,
  }

  const dx = useSpring(0, spring)
  const dy = useSpring(0, spring)

  useEffect(() => {
    dx.set(-rotateXaxis)
    dy.set(rotateYaxis)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotateXaxis, rotateYaxis])

  useEffect(() => {
    if (localStorage.getItem('user_onboarding')) {
      setTimeout(() => {
        setIsFlipped(true)
        setIsFlippedInactive(false)
      }, 2000)

      localStorage.removeItem('user_onboarding')
    }

    setIsFlippedInactive(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFlip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    // isCurrentCard &&
    setIsFlipped((prevState) => !prevState)
  }

  const getBookingFromOrder = (order: Order) => {
    const booking = wallet?.find((booking) => {
      return booking.orders.find((o) => {
        return o.uid === order.uid
      })
    })

    return booking as Booking
  }

  return (
    <>
      {walletOrders
        .sort((a: Order, b: Order) => {
          // First, compare by start_time
          const startTimeComparison =
            new Date(getBookingFromOrder(a).start_time).getTime() -
            new Date(getBookingFromOrder(b).start_time).getTime()

          // // If start_time is the same, compare by seat_name alphabetically
          if (startTimeComparison !== 0) {
            return startTimeComparison
          }

          return a.tickets[0].seat_name.localeCompare(b.tickets[0].seat_name)
        })
        .map((order: Order, index) => {
          const cardClass = determineClasses(
            order.tickets.length,
            indexes,
            index,
            api.y.get() > 0,
          )
          const isActive = cardClass === 'active'
          const isNext = cardClass === 'next'
          const isAfterNext = cardClass === 'afternext'
          const isPrev = cardClass === 'prev'
          const isInactive = cardClass === 'inactive'

          return (
            <div className={`w-full h-full`} key={index}>
              <motion.li
                initial={{
                  transform: isActive
                    ? 'translateY(2vh) scale(1)'
                    : isNext
                      ? 'translateY(9vh) scale(.95)'
                      : isAfterNext
                        ? 'translateY(18vh) scale(.9)'
                        : isInactive
                          ? 'scale(0)'
                          : '',
                }}
                animate={{
                  transform: isActive
                    ? 'translateY(0vh) scale(1)'
                    : isNext
                      ? 'translateY(-10vh) scale(.85)'
                      : isAfterNext
                        ? 'translateY(-17vh) scale(.75)'
                        : 'scale(0)',
                  filter: isActive
                    ? 'blur(0px) brightness(100%) opacity(1)'
                    : isNext
                      ? 'blur(.5px) brightness(80%) opacity(1)'
                      : isAfterNext
                        ? 'blur(1px) brightness(70%) opacity(.75)'
                        : 'blur(0px)',
                }}
                transition={{
                  type: 'spring',
                  stiffness: 200,
                  damping: 15,
                  mass: 0.05,
                }}
                className={`absolute w-full h-[85dvh] flex items-end
                ${cardClass}
                ${isActive && `z-30 scale-100`}
                ${isPrev && 'z-0 pointer-events-none'}
                ${isNext && 'z-20 pointer-events-none'}
                ${isAfterNext && 'z-10 pointer-events-none'}
                ${!isActive && !isNext && !isAfterNext && 'hidden'}
              `}
              >
                <animated.div
                  {...(walletOrders.length > 1 ? bind() : {})}
                  style={
                    isActive
                      ? {
                          transform: api.y
                            .to((y: any) => `translateY(${y}px)`)
                            .to(
                              (translateY: any) =>
                                `perspective(200px) ${translateY} rotateX(${api.rotateX.get()}deg)`,
                            ),
                          opacity: api.opacity,
                        }
                      : {}
                  }
                  className={`w-full h-full touch-none`}
                >
                  <CardFlipper
                    spring={spring}
                    dx={dx}
                    dy={dy}
                    isFlipped={isFlipped}
                    setIsFlipped={setIsFlipped}
                    isCurrentCard={index === indexes.currentIndex}
                    isFlippedInactive={isFlippedInactive}
                    FrontFace={
                      <TicketCard
                        handleFlip={handleFlip}
                        isFlipped={isFlipped}
                        isActiveTicket={index === indexes.currentIndex}
                        order={order}
                        booking={getBookingFromOrder(order)}
                      />
                    }
                    BackFace={
                      <TicketCardBackFace
                        handleFlip={isActive ? handleFlip : undefined}
                        order={order}
                        booking={getBookingFromOrder(order)}
                      />
                    }
                  />
                </animated.div>
              </motion.li>
            </div>
          )
        })}
    </>
  )
}
