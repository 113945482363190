import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Arrow } from '@/assets/Arrow'
import { CastContentItem } from '@/types/preshow'
import { ExpandableText } from '../../components/upseat-ui/contentChannel/ExpandableText'
import { HouseIcon } from '@/assets/HouseIcon'
import NavLayout from '@/layouts/NavLayout'
import { SeekBar } from '../../components/upseat-ui/contentChannel/SeekBar'
import { AnimatePresence, motion } from 'framer-motion'
import { transitionOut } from '@/utils'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { useMeetTheCastStore } from '@/context/useMeetTheCastStore'
import { useSwipeDirection } from '@/hooks/useSwipeDirection'
import { useUserStore } from '@/context/useUserStore'
import { Instagram, Redo, Undo } from 'lucide-react'

export const MeetTheCast = () => {
  const params = useParams()
  const { user } = useUserStore()
  const { duration, badge, casting } = useMeetTheCastStore()
  const { setFullScreenIndex } = useFullScreenContentStore()
  const [isExpanded, setIsExpanded] = useState(false)
  const navigate = useNavigate()
  const isFeed =
    new URLSearchParams(useLocation().search).get('feed') === 'true'
  const { swipeDirection, resetSwipeDirection } = useSwipeDirection()
  const [isExclusive, setIsExclusive] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [errorLoadingImage, setErrorLoadingImage] = useState(false)
  const castingContentArray = casting.content as CastContentItem[]
  const [userGuide, setUserGuide] = useState(castingContentArray?.length > 1)
  const [isPaused, setIsPaused] = useState(false)

  const [seekBarsProgress, setSeekBarsProgress] = useState<number[]>(
    Array(castingContentArray.length).fill(0),
  )
  const isSeekBarCompleteRef = useRef<boolean[]>(
    Array(castingContentArray.length).fill(false),
  )

  const animationFrameId = useRef<number | null>(null)
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        when: 'beforeChildren',
      },
    },
    exit: { opacity: 0, transition: transitionOut },
  }

  // Handle touch start to pause video
  const handleTouchStart = () => {
    setIsPaused(true)
  }

  // Handle touch end to resume video
  const handleTouchEnd = () => {
    setIsPaused(false)
  }

  useEffect(() => {
    if (swipeDirection && isFeed) {
      navigate(`/fullscreen/${params.id ?? ''}`)
    }

    resetSwipeDirection()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipeDirection])

  useEffect(() => {
    setTimeout(() => {
      setUserGuide(false)
    }, 2500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Adjust your effect for seek bar progress to respect isPaused
  useEffect(() => {
    if (userGuide || isPaused || isExpanded) return // Respect pause states
    setSeekBarsProgress((prevProgress) =>
      prevProgress.map((progress, index) =>
        index === currentIndex ? 0 : progress,
      ),
    )

    const updateProgress = () => {
      setSeekBarsProgress((prevProgress) =>
        prevProgress.map((progress, index) => {
          if (index < currentIndex) return duration / 1000 // Completed items
          if (index === currentIndex) {
            const newProgress = Math.min(progress + 1 / 120, duration / 1000)
            isSeekBarCompleteRef.current[currentIndex] =
              newProgress >= duration / 1000
            return newProgress
          }
          return progress // Future items remain unchanged
        }),
      )

      if (!isExpanded && !isPaused) {
        animationFrameId.current = requestAnimationFrame(updateProgress)
      }
    }

    cancelAnimationFrame(animationFrameId.current || 0)
    animationFrameId.current = requestAnimationFrame(updateProgress)

    return () => cancelAnimationFrame(animationFrameId.current || 0)
  }, [duration, currentIndex, isExpanded, isPaused, userGuide])

  const handleSkipContent = (next?: boolean) => {
    if (!next && currentIndex === 0) return

    setIsExpanded(false)

    setCurrentIndex((prevIndex) => {
      const isLastItem = next && prevIndex === castingContentArray.length - 1

      setSeekBarsProgress((prevProgress) =>
        prevProgress.map((progress, index) => {
          if (isLastItem) return 0 // Reset all items when reaching the end
          if (next) {
            if (index < prevIndex) return duration / 1000 // Completed items
            if (index === prevIndex) return 0 // Reset current item
          } else {
            if (index === prevIndex) return 0 // Reset current item
            if (index < prevIndex) return duration / 1000 // Keep completed items
          }
          return progress
        }),
      )

      // Adjust index based on direction
      return next
        ? (prevIndex + 1) % castingContentArray.length
        : prevIndex === 0
          ? castingContentArray.length - 1
          : prevIndex - 1
    })
  }

  return (
    <NavLayout>
      <AnimatePresence>
        <motion.section
          id="meet-the-cast"
          className={`flex flex-col justify-start items-center w-full h-[100svh]`}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={containerVariants}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {!!user && (
            <motion.span
              onClick={() => {
                navigate(`/tickets`)
              }}
              className="fixed cursor-pointer top-0 right-0 m-4 z-50 bg-purple text-white rounded-full w-8 h-8 flex items-center justify-center"
            >
              <HouseIcon />
            </motion.span>
          )}

          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={() => {
              setFullScreenIndex(0)
              navigate(`/channel/${params.id ?? ''}`)
            }}
            className="fixed cursor-pointer top-0 left-0 m-4 z-50 bg-purple1 text-white rounded-full w-8 h-8 flex items-center justify-center"
          >
            <Arrow />
          </motion.span>

          {castingContentArray.length > 1 && (
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: userGuide ? 1 : 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className={`w-full h-[100svh] object-cover absolute top-0 z-10 flex items-center justify-between p-4 bg-[linear-gradient(to_top,rgba(0,0,0,.6)_100%,rgba(0,0,0,0)_100%)]`}
            >
              {/* Animated Undo Arrow */}
              <motion.div
                animate={{ x: [0, -10, 0] }} // Moves left (-10px) and back (0px)
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  ease: 'easeInOut',
                }}
              >
                <Undo size={30} />
              </motion.div>

              <p className="font-headline">{`touch to skip`}</p>

              {/* Animated Redo Arrow */}
              <motion.div
                animate={{ x: [0, 10, 0] }} // Moves right (10px) and back (0px)
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  ease: 'easeInOut',
                }}
              >
                <Redo size={30} />
              </motion.div>
            </motion.div>
          )}

          <motion.div className="relative m-0 w-full h-full min-h-[100svh] flex flex-col justify-center">
            <div className="relative w-full h-full">
              {isExclusive && !userGuide && (
                <div className="w-full h-full flex justify-center">
                  <span className="absolute top-0 m-4 bg-yellow text-purple text-sm rounded-full py-[3px] px-3">{`Exclusive`}</span>
                </div>
              )}

              <div
                className={`absolute top-0 z-40 w-full h-full flex ${userGuide && 'pointer-events-none'}`}
              >
                <div
                  className="w-[50%] h-[100svh]"
                  onClick={() => handleSkipContent()}
                ></div>
                <div
                  className="w-[50%] h-[100svh]"
                  onClick={() => handleSkipContent(true)}
                ></div>
              </div>

              {!errorLoadingImage && (
                <img
                  src={castingContentArray[currentIndex].content}
                  alt={castingContentArray[currentIndex].content}
                  draggable={false}
                  className={`w-full h-[100svh] object-cover absolute top-0`}
                  onError={() => setErrorLoadingImage(true)}
                />
              )}

              {errorLoadingImage && (
                <div className="w-full h-[100svh]">
                  <p className="absolute inset-0 z-20 text-white font-headline text-center flex items-center justify-center">
                    {`Error loading image`}
                  </p>
                </div>
              )}

              <div className="absolute inset-0 z-20 pointer-events-none" />
            </div>

            {!userGuide && (
              <div className="w-full items-center justify-between absolute py-4 p-4 bottom-0 flex z-50 bg-[linear-gradient(to_top,rgba(0,0,0,.5)_90%,rgba(0,0,0,0)_100%)]">
                <div className="flex flex-col w-full">
                  <div className="w-full flex flex-col gap-4 items-center justify-start">
                    <div className="w-full flex gap-2 items-center">
                      <img
                        src={badge}
                        alt={badge}
                        draggable={false}
                        className="w-8 h-8 rounded-[4px]"
                      />
                      <p className="text-sm font-bold">
                        {castingContentArray[currentIndex].name}
                      </p>
                      <a
                        href="https://www.instagram.com/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Instagram size={18} />
                      </a>
                    </div>

                    <p className="w-full text-sm font-bold">
                      {`Character: `}
                      <span className="font-normal">
                        {castingContentArray[currentIndex].character}
                      </span>
                    </p>

                    <ExpandableText
                      text={castingContentArray[currentIndex].description}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      limit={150}
                    />
                  </div>

                  <div className="relative flex flex-col items-center bg-transparent pb-4 pt-6">
                    <div className="w-full flex items-center gap-2">
                      {castingContentArray.map(
                        (content: any, index: number) => (
                          <SeekBar
                            key={index}
                            currentIndex={currentIndex}
                            duration={duration / 1000}
                            currentTime={seekBarsProgress[index]}
                            setCurrentTime={setCurrentTime}
                            isActive={index === currentIndex}
                            handleSkipContent={handleSkipContent}
                          />
                        ),
                      )}
                      <style>{`
              input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 0;
                height: 0;
              }
              input[type='range']::-moz-range-thumb {
                width: 0;
                height: 0;
              }
            `}</style>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        </motion.section>
      </AnimatePresence>
    </NavLayout>
  )
}
