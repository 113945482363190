import { useEffect, useState } from 'react'

import { CartSummary } from './CartSummary'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { Skeleton } from '@/components/ui/skeleton'
import { Stripe } from '@/pages/payment'
import { postBasketConfirmation } from '@/service/Basket/postConfirmation'
import { postIntent } from '@/service/Stripe/postIntent'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useCartStore } from '@/context/useCartStore'
import useEventStore from '@/context/useEventStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useNavigate } from 'react-router-dom'
import { useTicketsStore } from '@/context/useTicketsStore'

export const BookingSummary = () => {
  const navigate = useNavigate()
  const callRouteWithDirection = useCallRouteWithDirection()
  const { booking, setBooking } = useExistingBookingStore()
  const { loadingPayment } = useCartStore()
  const { basket, removeBasket, setBasket } = useTicketsStore()
  const { setServerError } = useAppStore()
  const { setSelectedSeats, setMapSeats } = useEventStore()
  const { seatsPendingPayment } = useCartStore()
  const [timeToReserve, setTimeToReserve] = useState<string>('')
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    if (!basket) {
      navigate('/seat-map')
    }

    if (!termsModalOpen) {
      setTimeout(() => {
        setTermsModalOpen(true)
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (basket) {
      setTimeout(async () => {
        const currentTime = new Date().getTime()
        const expirationTime = new Date(basket?.expires_utc).getTime()

        if (expirationTime < currentTime) {
          removeBasket()
          setSelectedSeats(undefined)
          navigate('/seat-map')
        }

        const timeDifferenceInSeconds = Math.abs(
          (expirationTime - currentTime) / 1000,
        ) // Convert milliseconds to seconds

        // Calculate minutes and seconds
        const minutes = Math.floor(timeDifferenceInSeconds / 60)
        const seconds = Math.floor(timeDifferenceInSeconds % 60)

        // Format result into MM:SS format
        const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`
        setTimeToReserve(formattedTime)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeToReserve])

  useEffect(() => {
    if (basket?.upgrade_uid) {
      postIntent(basket.upgrade_uid)
        .then(async (res) => {
          setClientSecret(res.intent.client_secret)
        })
        .catch(async (err) => {
          console.log('Stripe Post Intent Error:', err)
          await setServerError(true)
          await removeBasket()
          callRouteWithDirection('/seat-map', true, 1)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const confirmTickets = async () => {
    if (basket)
      postBasketConfirmation(basket.upgrade_uid)
        .then(async () => {
          // Clear the browser store to force update from the server
          await removeBasket()
          await setSelectedSeats([])
          await setBooking(undefined)
          callRouteWithDirection('/tickets', true, 1)
        })
        .catch((err) => {
          console.log(
            'Error posting basket confirmation:',
            err.response.data.error,
          )
          setServerError(true)
        })
  }

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={'booking summary'}
            goBack={
              !loadingPayment
                ? () => {
                    callRouteWithDirection('/seat-map', true, 1)
                  }
                : undefined
            }
          />
        </div>
        <div
          data-testid="booking-summary"
          className="bg-white min-h-[90svh] overflow-scroll flex flex-col justify-between gap-8 text-purple p-6 pt-4"
        >
          <div>
            {timeToReserve && !loadingPayment ? (
              <div className="flex">
                <p className="bg-purple3 rounded-[20px] text-white font-body px-4 py-1 w-fit">
                  {`Reserved for `}
                  <span className="font-bold">{timeToReserve}</span>
                  {` minutes`}
                </p>
              </div>
            ) : (
              <Skeleton className="bg-purple3/30 w-[65%] h-7" />
            )}
            <div className="text-purple bg-purple7 rounded-[20px] py-6 px-4 my-4">
              <div className="flex flex-col gap-2 justify-between w-full border-b border-grey2 pb-4">
                {basket && (
                  <CartSummary
                    selectedSeats={seatsPendingPayment}
                    basket={basket}
                  />
                )}
              </div>
              {/* <div className="flex justify-between border-b border-grey2 py-4">
                <p>{`Upgrade fee`}</p>
                <p className="flex gap-1 items-center">
                  <span>{`${seatsPendingPayment.length} x`}</span>
                  {`£${basket?.seats
                    .reduce((sum, seat) => {
                      return sum + seat.band.active_discount
                    }, 0)
                    .toFixed(2)}`}
                </p>
              </div> */}
              <div className="flex justify-between font-semibold pt-4">
                <p>{`Total`}</p>
                <p className="flex justify-center items-center gap-2">
                  <span className="bg-purple text-white text-sm font-light rounded-[40px] px-3 py-1">
                    {`Save £` +
                      seatsPendingPayment.reduce((sum, seat) => {
                        return sum + seat.original_price * seat.discount
                      }, 0)}
                  </span>
                  {`£${basket?.total_price.toFixed(2)}`}
                </p>
              </div>
            </div>
            <Stripe
              clientSecret={clientSecret}
              confirmTickets={confirmTickets}
            />
          </div>
        </div>
      </RouteTransition>
    </DefaultLayout>
  )
}
