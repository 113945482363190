import useEventStore from '@/context/useEventStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useTicketsStore } from '@/context/useTicketsStore'
import { getPlan } from '@/service/Seats/getPlan'
import { PriceBand, Seat } from '@/types'
import { Booking, Order } from '@/types/booking'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import useCallRouteWithDirection from './useCallRouteWithDirection'

export function useSeatMap() {
  const { setOrder, setBooking } = useExistingBookingStore()
  const callRouteWithDirection = useCallRouteWithDirection()
  const { setMapSeats, setPriceBands, searchingSeats, setSearchingSeats } =
    useEventStore()
  const { wallet, removeBasket } = useTicketsStore()
  const navigate = useNavigate()

  const getBookingFromOrder = (order: Order) => {
    const booking = wallet?.find((booking) => {
      return booking.orders.find((o) => {
        return o.uid === order.uid
      })
    })

    return booking as Booking
  }

  const getSeatMap = useCallback(
    async (orderSelected: Order) => {
      setOrder(orderSelected)
      setBooking(getBookingFromOrder(orderSelected))
      setSearchingSeats(true)
      setMapSeats(undefined)

      await removeBasket()
      await setPriceBands([])

      if (wallet) {
        try {
          const plan = await getPlan(orderSelected?.uid as string)
          if (!plan.bands || !!plan.error) {
            console.error('Error fetching plan')
            setSearchingSeats(false)
            navigate('/tickets')
            return
          }

          const sortedPriceBands = plan.bands
            .sort(
              (a: PriceBand, b: PriceBand) =>
                (a.discount_price ?? 0) - (b.discount_price ?? 0),
            )
            .filter(
              (band: PriceBand) =>
                band.discount_price && band.discount_price > 0,
            )

          setPriceBands(sortedPriceBands)

          const updatedSeats = plan.seats?.map((seat: Seat) => {
            const priceBandIndex = sortedPriceBands.findIndex(
              (priceBand: PriceBand) => priceBand.uid === seat.band_uid,
            )

            return {
              ...seat,
              tier: priceBandIndex + 1,
              upgrade_price: sortedPriceBands[priceBandIndex]?.discount_price,
              original_price: sortedPriceBands[priceBandIndex]?.original_price,
              discount: sortedPriceBands[priceBandIndex]?.active_discount,
            }
          })

          setMapSeats(updatedSeats)
          callRouteWithDirection('/seat-map', false, 1)
          setSearchingSeats(false)
        } catch (error) {
          console.error('Error fetching seat map:', error)
          setSearchingSeats(false)
        }
      } else {
        setSearchingSeats(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wallet, navigate],
  )

  return { getSeatMap, searchingSeats, setSearchingSeats }
}
