import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { SeekBar } from './SeekBar'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'
import { motion } from 'framer-motion'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { ExpandableText } from './ExpandableText'

interface MainVideoProps {
  childVariants: any
  badge: string
  video_url: string
  video_description: string
  name: string
  isExclusive: boolean
  setMainContentLoaded: (value: boolean) => void
}

export const MainVideo = ({
  childVariants,
  badge,
  video_url,
  video_description,
  name,
  isExclusive,
  setMainContentLoaded,
}: MainVideoProps) => {
  const params = useParams()
  const navigate = useNavigate()
  const { setMainContent } = useFullScreenContentStore()

  const containerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(true)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)

  // Update play/pause state based on visibility
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => setIsPlaying(entry.isIntersecting), 100) // Debounce updates
      },
      { threshold: 0.8 },
    )

    if (containerRef.current) observer.observe(containerRef.current)

    return () => {
      observer.disconnect()
      if (timeout) clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted
    }
  }, [isMuted])

  // Handle video play/pause with buffering logic
  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    let isCanceled = false

    const handlePlayPause = async () => {
      try {
        if (isPlaying) {
          // Check if sufficient buffer is available before playing
          const handleBuffering = () => {
            const buffered = video.buffered
            if (
              buffered.length > 0 &&
              buffered.end(0) > video.currentTime + 2
            ) {
              // Play only if at least 2 seconds are buffered
              video.play().catch(console.error)
            } else {
              setTimeout(handleBuffering, 200) // Retry after 200ms
            }
          }
          handleBuffering()
        } else {
          video.pause()
        }
      } catch (error) {
        if (!isCanceled) console.error(error)
      }
    }

    handlePlayPause()

    return () => {
      isCanceled = true // Avoid issues if state changes during cleanup
    }
  }, [isPlaying])

  // Handle metadata load and video readiness
  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration)
      setMainContentLoaded(true)
    }
  }

  const handleTimeUpdate = () => {
    if (videoRef.current) setCurrentTime(videoRef.current.currentTime)
  }

  const handleError = () => {
    navigate(`/channel${params.id ?? ''}`)
  }

  return (
    <motion.div
      ref={containerRef}
      id="channel-main-video"
      variants={childVariants}
      className={`relative m-0 w-full`}
      initial={{ opacity: 0 }}
      animate={{
        opacity: duration ? 1 : 0,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
    >
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        className={`w-full h-auto object-cover ${!duration && 'hidden'}`}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleTimeUpdate}
        onClick={(e) => {
          e.stopPropagation()
          setMainContent({ badge, video_url, video_description, name })
          navigate(`/fullscreen/${params.id ?? ''}`)
        }}
        onError={handleError}
      >
        <source src={video_url} />
      </video>

      {isExclusive && (
        <span className="absolute top-0 right-0 m-4 bg-yellow text-purple text-sm rounded-full py-[3px] px-3">
          Exclusive
        </span>
      )}

      <div
        className={`absolute bottom-0 w-full flex flex-col gap-2 p-4 ${
          name &&
          video_description &&
          'bg-[linear-gradient(to_top,rgba(0,0,0,.6)_80%,rgba(0,0,0,0)_100%)]'
        }`}
      >
        <div className={`flex justify-between items-center`}>
          <div className={`flex gap-2 w-[90%]`}>
            <img
              src={badge}
              alt={name}
              className="w-8 h-8 rounded-[4px]"
              draggable={false}
            />
            <div className="flex flex-col w-full">
              <p className="font-bold">{name}</p>
              {video_description && (
                <div className="w-full">
                  <ExpandableText
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    text={video_description}
                    limit={60}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className="cursor-pointer z-50 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
            onClick={() => setIsMuted(!isMuted)}
          >
            {isMuted ? <VolumeOff /> : <VolumeOn />}
          </div>
        </div>

        {duration > 0 && (
          <div className="relative flex flex-col items-center bg-transparent py-2">
            <SeekBar
              videoRef={videoRef}
              currentIndex={0}
              duration={duration}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              ref={videoRef}
              isActive
            />
            {/* Custom styles to hide the default thumb */}
            <style>
              {`
                        input[type='range']::-webkit-slider-thumb {
                          -webkit-appearance: none;
                          appearance: none;
                          width: 0;
                          height: 0;
                        }
                        input[type='range']::-moz-range-thumb {
                          width: 0;
                          height: 0;
                        `}
            </style>
          </div>
        )}
      </div>
    </motion.div>
  )
}
