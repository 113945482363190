import { AnimatePresence, motion } from 'framer-motion'
import { Booking, Order } from '@/types/booking'
import { useEffect, useRef, useState } from 'react'
import {
  deleteDatabase,
  fetchAllBookingsFromDB,
  startInterval,
  stopInterval,
  transition,
} from '@/utils'

import { Button } from '@/components/ui/button'
import { CardList } from './CardList'
import LoadingIndicator from '../seatMap/LoadingIndicator'
import { TicketsIcon } from '@/assets/navIcons/TabBarIcons'
import { getWallet } from '@/service/Wallet/getWallet'
import { set } from 'idb-keyval'
import { syncBooking } from '@/service/Booking/syncBooking'
import { syncSilent } from '@/service/Booking/syncSilent'
import { useDrag } from '@use-gesture/react'
import { useIndexes } from './useIndexes'
import { useSpring } from '@react-spring/web'
import { useTicketsStore } from '@/context/useTicketsStore'
import { useUserStore } from '@/context/useUserStore'

interface Indexes {
  previousIndex: number
  currentIndex: number
  nextIndex: number
  afternextIndex: number
}

export const determineClasses = (
  walletLength: number,
  indexes: Indexes,
  cardIndex: number,
  isBackward: boolean,
) => {
  if (indexes.currentIndex === cardIndex) return 'active'

  if (isBackward) {
    // Logic when swiping backward
    if (indexes.previousIndex === cardIndex) return 'next' // When going backward, prev becomes next
    if (indexes.nextIndex === cardIndex) return 'prev' // The next card in forward swipe is the previous in backward
    if (indexes.afternextIndex === cardIndex) return 'afternext' // The afternext should be inactive when going backward
  } else {
    // Logic when swiping forward
    if (indexes.nextIndex === cardIndex) return 'next'
    if (indexes.previousIndex === cardIndex && walletLength > 3) return 'prev'
    if (
      indexes.afternextIndex === cardIndex ||
      (indexes.previousIndex === cardIndex && walletLength === 3)
    )
      return 'afternext'
  }

  return walletLength === 2 ? 'next' : 'inactive'
}

export const TicketStack = () => {
  const { user } = useUserStore()
  const { wallet, setWallet } = useTicketsStore()
  const getWalletIntervalRef = useRef<null>(null)
  const getWalletCallCounter = useRef<number>(0)
  const [loadingTickets, setLoadingTickets] = useState(true)
  const [syncLoading, setSyncLoading] = useState(false)
  const [backwardBlock, setBackwardBlock] = useState(true)
  const [walletOrders, setWalletOrders] = useState<Order[]>([])
  const { indexes, handleCardTransition } = useIndexes(
    walletOrders ? walletOrders.length : 0,
  )

  const [{ y, rotateX, opacity }, api] = useSpring(() => ({
    y: 0,
    rotateX: 0,
    scale: 1,
    opacity: 1,
  }))

  const bind = useDrag(
    ({ down, movement: [, my] }) => {
      const rotation = -my / 40
      const scaling = down ? 0.95 : 1
      const opacityValue = 1 // Adjust if opacity changes are needed

      const animateSequence = (steps: any[]) => {
        const [current, ...rest] = steps
        if (current) {
          api.start({
            ...current,
            onRest: () => animateSequence(rest),
          })
        }
      }

      const forwardAnimationSteps = [
        {
          y: -600,
          rotateX: rotation,
          opacity: 1,
          scale: 1,
          config: { duration: 200, friction: 10 },
        },
        {
          y: -750,
          rotateX: 0,
          opacity: 0,
          scale: 0,
          config: { duration: 200 },
        },
        {
          y: 300,
          rotateX: -(rotation / 2),
          opacity: 0,
          scale: 0,
          config: { duration: 50 },
        },
        {
          y: -5,
          rotateX: 0,
          opacity: 0.95,
          scale: 0.95,
          config: { duration: 300 },
        },
        { y: 0, opacity: 1, scale: 1, config: { duration: 300 } },
      ]

      const backwardAnimationSteps = [
        {
          y: 500,
          rotateX: rotation,
          opacity: 1,
          scale: 1,
          config: { duration: 200, friction: 10 },
        },
        { y: 750, rotateX: 0, opacity: 0, scale: 0, config: { duration: 200 } },
        {
          y: -300,
          rotateX: -(rotation / 2),
          opacity: 0,
          scale: 0,
          config: { duration: 50 },
        },
        {
          y: 5,
          rotateX: 0,
          opacity: 0.95,
          scale: 0.95,
          config: { duration: 300 },
        },
        { y: 0, opacity: 1, scale: 1, config: { duration: 300 } },
      ]

      api.start(
        walletOrders && walletOrders.length > 1
          ? {
              y: down ? my : 0,
              rotateX: down ? rotation : 0,
              scale: scaling,
              opacity: opacityValue,
            }
          : {},
      )

      if (!down && my < 250) {
        if (my < -50) {
          animateSequence(forwardAnimationSteps)
          setBackwardBlock(false)
          handleCardTransition(false)
        } else {
          api.start({ y: 0, rotateX: 0, scale: 1, opacity: 1 })
        }
      } else if (!down && my > -250 && !backwardBlock) {
        animateSequence(backwardAnimationSteps)
        handleCardTransition(true)
      }
    },
    { axis: 'y' },
  )

  const loadUserTickets = () => {
    getWallet()
      .then(async (res: Booking[]) => {
        // Fetch current tickets from the browser database
        const bookingsFromIndexedDB = await fetchAllBookingsFromDB()

        const hasOrderDiff = (existing: Booking[], newData: Booking[]) => {
          if (existing.length !== newData.length) return true

          for (var i = 0; i < existing.length; i++) {
            var existingEvent = existing[i]
            var newEvent = newData[i]

            // Check if event properties differ
            if (
              existingEvent.event_name !== newEvent.event_name ||
              existingEvent.start_time !== newEvent.start_time
            ) {
              return true
            }

            // Check if orders length differ
            if (existingEvent.orders.length !== newEvent.orders.length) {
              return true
            }

            // Compare each order by order_id
            var existingOrderIds = existingEvent.orders.map(function (order) {
              return order.order_id
            })
            var newOrderIds = newEvent.orders.map(function (order) {
              return order.order_id
            })

            for (var j = 0; j < existingOrderIds.length; j++) {
              if (newOrderIds.indexOf(existingOrderIds[j]) === -1) {
                return true
              }
            }

            for (var k = 0; k < newOrderIds.length; k++) {
              if (existingOrderIds.indexOf(newOrderIds[k]) === -1) {
                return true
              }
            }
          }

          return false
        }

        if (
          !bookingsFromIndexedDB.length ||
          hasOrderDiff(bookingsFromIndexedDB, res)
        ) {
          deleteDatabase('keyval-store')
          setWallet(res)

          res.forEach(async (booking) => {
            await set(booking.event_name + booking.start_time, booking)
          })
        } else {
          setWallet(bookingsFromIndexedDB)
        }
      })
      .catch(async (error) => {
        console.log('Error fetching wallet:', error)
      })
      .finally(() => {
        setSyncLoading(false)
        setLoadingTickets(false) // Ensure loading state is updated on error
      })

    getWalletCallCounter.current += 1

    if (getWalletCallCounter.current === 3) {
      syncSilent()
      getWalletCallCounter.current = 0 // Reset the counter
    }
  }

  useEffect(() => {
    if (wallet) {
      setWalletOrders(wallet.flatMap((booking) => booking.orders) as Order[])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet])

  useEffect(() => {
    setSyncLoading(true)
    loadUserTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async () => {
      const bookingsDB = await fetchAllBookingsFromDB()
      if (bookingsDB.length) {
        setWallet(bookingsDB)
        setLoadingTickets(false)
      }
    })()

    if (user) {
      startInterval(getWalletIntervalRef, loadUserTickets, 10000)
    }

    return () => {
      stopInterval(getWalletIntervalRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <AnimatePresence>
      {wallet?.length ? (
        <CardList
          walletOrders={walletOrders}
          indexes={indexes}
          bind={bind}
          api={{ y, rotateX, opacity }}
        />
      ) : (
        <div className="relative bottom-[-3vh] w-full flex justify-center items-center px-6">
          <div className="bg-purple brightness-125 w-full max-w-[85vw] h-[85svh] rounded-[35px]">
            <div className="z-30 flex flex-col gap-2 h-full items-center justify-center [&>svg]:h-[64px] [&>svg]:w-[64px] transition duration-180 ease-default-cubic fill-purple4">
              {TicketsIcon()}
              {syncLoading ? (
                <AnimatePresence>
                  <motion.div
                    transition={transition}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex flex-col gap-1 items-center justify-center [&>svg]:h-[64px] [&>svg]:w-[64px]"
                  >
                    <p>{`Syncing your tickets`}</p>
                    <span className="my-2">
                      <LoadingIndicator />
                    </span>
                  </motion.div>
                </AnimatePresence>
              ) : (
                <motion.div
                  transition={transition}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="w-full flex flex-col gap-1 justify-center items-center my-2"
                >
                  <p id="wallet-refresh-text">{'Can’t see your tickets?'}</p>
                  <Button
                    variant={'primaryYellow'}
                    className="py-2 rounded-[40px] mt-2"
                    id="wallet-refresh-btn"
                    onClick={async () => {
                      setSyncLoading(true)
                      setWallet(undefined)

                      syncBooking().then(() => {
                        loadUserTickets()
                      })
                    }}
                  >{`Refresh`}</Button>
                </motion.div>
              )}
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  )
}
