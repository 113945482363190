import { Channel } from '@/types/preshow'
import { create } from 'zustand'

// Define the store's state
type ContentChannelState = {
  channelContent: Channel
  setChannelContent: (value: Channel) => void
}

// Create the store
export const useContentChannelStore = create<ContentChannelState>((set) => ({
  channelContent: {
    uid: '',
    name: '',
    description: '',
    badge: '',
    channel: [],
    actions: [],
  },
  setChannelContent: (value) => set({ channelContent: value }),
}))
