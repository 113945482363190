import { Navigate, Route, Routes } from 'react-router-dom'

import { Account } from '@/pages/account/Account'
import { BookingSummary } from '@/pages/seatMap/bookingSummary/BookingSummary'
import { HelpCentre } from '@/pages/account/HelpCentre'
import { Partners } from '@/pages/account/Partners'
import { PaymentDetails } from '@/pages/account/PaymentDetails'
import { PersonalDetails } from '@/pages/account/PersonalDetails'
import SeatMap from '@/pages/seatMap/seatMap'
import { Sync } from '@/pages/sync/Sync'
import { TicketReceipt } from '../tickets/TicketReceipt'
import { User } from '@/types'
import { Wallet } from '@/pages/wallet'

const ProtectedRoutes = ({ user }: { user: User }) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/tickets" replace />} />
      <Route path="*" element={<Navigate to="/tickets" replace />} />
      <Route path="/tickets" element={<Wallet />} />
      <Route path="/sync" element={<Sync user={user} />} />
      <Route path="/seat-map" element={<SeatMap />} />
      <Route path="/personal-details" element={<PersonalDetails />} />
      <Route path="/account" element={<Account />} />
      <Route path="/payment-details" element={<PaymentDetails />} />
      <Route path="/help-centre" element={<HelpCentre />} />
      <Route path="/partners" element={<Partners />} />
      <Route path="/booking-summary" element={<BookingSummary />} />
      <Route path="/tickets/receipt/:id" element={<TicketReceipt />} />
    </Routes>
  )
}

export default ProtectedRoutes
