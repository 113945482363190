import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CountryCode, User } from '@/types'
import {
  personalDetailsFormSchema,
  personalDetailsFormSchemaType,
} from '@/lib/validation-schemas/personalDetailsFormSchema'

import { Button } from '@/components/ui/button'
import { ChangePassword } from './ChangePassword'
import { CountrySelect } from '@/components/upseat-ui/account/CountrySelect'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import { Input } from '@/components/ui/input'
import LoadingIndicator from '@/components/upseat-ui/seatMap/LoadingIndicator'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { updateUser } from '@/service/User/updateUser'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useState } from 'react'
import { useUserStore } from '@/context/useUserStore'
import { zodResolver } from '@hookform/resolvers/zod'

export const PersonalDetails = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const { user, setUser } = useUserStore()
  const [changePasswordPage, setChangePasswordPage] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [newFirstName, setNewFirstName] = useState<string>(
    user?.firstname || '',
  )
  const [newLastName, setNewLastName] = useState<string>(user?.lastname || '')
  const [newEmail, setNewEmail] = useState<string>(user?.email || '')
  const [newCountryCode, setNewCountryCode] = useState<CountryCode>({
    code: '+44',
    acronym: 'GB',
    digits: '44',
  })
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>(
    user?.phoneNumber ?? '',
  )

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<personalDetailsFormSchemaType>({
    resolver: zodResolver(personalDetailsFormSchema),
  })

  const onSubmit: SubmitHandler<personalDetailsFormSchemaType> = async () => {
    setLoading(true)
    await updateUser({
      firstName: newFirstName,
      lastName: newLastName,
      phoneNumber: newPhoneNumber,
    })
      .then((res: any) => {
        setUser({
          ...user,
          firstname: res.data.first_name,
          lastname: res.data.last_name,
          phoneNumber: res.data.phone_number,
          countryCode: {
            code: newCountryCode.code,
            acronym: newCountryCode.acronym,
            digits: newCountryCode.digits,
          },
        } as User)
      })
      .catch((err) => {
        console.log('ERROR ->', err)
      })
      .finally(() => {
        setLoading(false)
        callRouteWithDirection('/tickets', true, 3)
      })
  }

  return (
    <DefaultLayout>
      {!changePasswordPage ? (
        <RouteTransition>
          <div className="flex flex-col justify-start gap-4">
            <HeadlineBar
              title={`personal details`}
              goBack={() => callRouteWithDirection('/tickets', true, 3)}
            />
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white overflow-scroll flex flex-col justify-between gap-4 text-purple h-[90svh] p-4 py-6"
            autoComplete={'off'}
          >
            <div className="flex flex-col gap-4">
              <div className="flex gap-2">
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={user?.firstname}
                  render={({ field }) => (
                    <div className="flex flex-col w-[50%]">
                      <p className="uppercase text-[12px] pl-2">
                        {'first name'}
                      </p>
                      <Input
                        className={`${
                          errors.firstName?.message && 'border-red'
                        } py-4`}
                        placeholder="First Name"
                        {...field}
                        onChange={(e) => {
                          setNewFirstName(e.target.value)
                          field.onChange(e)
                        }}
                      />
                      {errors.firstName && (
                        <p className="text-[12px] text-pretty pl-2 text-red">
                          {errors.firstName.message}
                        </p>
                      )}
                    </div>
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={user?.lastname}
                  render={({ field }) => (
                    <div className="flex flex-col w-[50%]">
                      <p className="uppercase text-[12px] pl-2">
                        {'last name'}
                      </p>
                      <Input
                        className={`${
                          errors.lastName?.message && 'border-red'
                        } py-4`}
                        placeholder="Last Name"
                        {...field}
                        onChange={(e) => {
                          setNewLastName(e.target.value)
                          field.onChange(e)
                        }}
                      />

                      {errors.lastName && (
                        <p className="text-[12px] text-pretty pl-2 text-red">
                          {errors.lastName.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>

              <div className="flex flex-col">
                <p className="uppercase text-[12px] pl-2">{'email'}</p>
                <Input
                  className={`py-4`}
                  defaultValue={user?.email}
                  disabled
                  placeholder="Email Address"
                  onChange={(e) => {
                    setNewEmail(e.target.value)
                  }}
                />
              </div>

              {/* <p
                className="relative my-2 text-left text-purple3 w-fit"
                onClick={() => setChangePasswordPage(true)}
              >{`Change Password`}</p> */}

              <div className="flex flex-col">
                <p className="uppercase text-[12px] pl-2">{'phone number'}</p>
                <div className="flex gap-1">
                  <CountrySelect
                    selected={newCountryCode}
                    setValue={setNewCountryCode}
                    error={!!errors.phoneNumber}
                  />
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue={user?.phoneNumber ?? ''}
                    render={({ field }) => (
                      <div className="flex flex-col w-full">
                        <Input
                          className={`${
                            errors.phoneNumber?.message && 'border-red'
                          } py-4 rounded-[4px_40px_40px_4px]`}
                          {...field}
                          placeholder="Enter your phone number"
                          onChange={(e) => {
                            setNewPhoneNumber(e.target.value)
                            field.onChange(e)
                          }}
                        />

                        {errors.phoneNumber && (
                          <p className="text-[12px] text-nowrap text-red">
                            {errors.phoneNumber.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 z-[0]">
              {!loading ? (
                <Button
                  variant="primaryDark"
                  disabled={
                    newFirstName === user?.firstname &&
                    newLastName === user?.lastname &&
                    newEmail === user?.email &&
                    newPhoneNumber === user?.phoneNumber &&
                    newCountryCode === user?.countryCode
                  }
                  className="w-full"
                >{`Save changes`}</Button>
              ) : (
                <div className="py-4">
                  <LoadingIndicator dark />
                </div>
              )}
            </div>
          </form>
        </RouteTransition>
      ) : (
        <ChangePassword setChangePasswordPage={setChangePasswordPage} />
      )}
    </DefaultLayout>
  )
}
