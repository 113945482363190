import { Booking, Order } from '@/types/booking'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '../tickets/qrCodeCarousel'

import Barcode from 'react-barcode'
import { ManageTicket } from './ManageTicket'
import { QRCodeSVG } from 'qrcode.react'
import { TicketButton } from './TicketButton'
import { TicketInfo } from './TicketInfo'
import useScreenSize from '@/hooks/useScreenSize'
import { useState } from 'react'
import { useSeatMap } from '@/hooks/useSeatMap'

type TicketCardBackFaceProps = {
  booking: Booking
  order: Order
  handleFlip?: () => void
}

export function TicketCardBackFace({
  booking,
  order,
  handleFlip,
}: TicketCardBackFaceProps) {
  const screenSize = useScreenSize()
  const { getSeatMap } = useSeatMap()
  const [carouselCurrentIndex, setCarouselCurrentIndex] = useState(0)
  const [manageTicket, setManageTicket] = useState(false)

  booking.orders.map((order: Order) => {
    return order.tickets.sort((a, b) => {
      const matchA = a.seat_name.match(/\d+/)
      const matchB = b.seat_name.match(/\d+/)
      const numA = matchA ? parseInt(matchA[0]) : 0
      const numB = matchB ? parseInt(matchB[0]) : 0
      return numA - numB
    })
  })

  return (
    <>
      <div
        className={`${order.state.upgrade.code === 3 && !manageTicket ? `shadow-[0_0_10px_0px_yellow]` : `shadow-[0_0_12px_0px_#CDC3FB]`}
        rounded-[35px] bg-white rounded-[35px] relative flex flex-col items-center
        w-[80vw] h-full max-h-[700px] max-w-[85vw] px-[5%]`}
        onClick={() => {
          if (manageTicket) {
            setManageTicket(false)
          } else {
            handleFlip && handleFlip()
          }
        }}
      >
        <div className={`w-full h-[75%] flex flex-col py-12`}>
          <div className="h-auto w-[inherit] flex flex-col justify-start">
            <div
              className={`flex flex-col ${booking.config?.is_qr ? 'mt-4 gap-0' : 'gap-2'}  w-full items-center px-2 [&>div]:flex [&>div]:justify-between [&>div]:w-full`}
            >
              <div className="w-full items-center flex flex-col">
                <div
                  className={`w-full flex justify-between py-[1.25vh] border-b border-grey2 border-solid ${screenSize.width > 380 ? 'max-w-[246px]' : 'max-w-[196px]'}`}
                >
                  <p className="uppercase text-purple font-light text-sm">{`section`}</p>
                  <p className="text-purple text-sm font-semibold">
                    {order.tickets[carouselCurrentIndex].plan_name}
                  </p>
                </div>

                <div
                  className={`w-full flex justify-between  py-3 border-b border-grey2 border-solid ${screenSize.width > 380 ? 'max-w-[246px]' : 'max-w-[196px]'}`}
                >
                  <p className="uppercase text-purple font-light text-sm">{`row`}</p>
                  <p className={`text-purple text-sm font-semibold`}>
                    <span className="text-purple text-sm font-semibold">
                      {order.tickets[carouselCurrentIndex].seat_name.replace(
                        /[^a-zA-Z]/g,
                        '',
                      )}
                    </span>
                  </p>
                </div>

                <div
                  className={`w-full flex justify-between py-[1.25vh] ${screenSize.width > 380 ? 'max-w-[246px]' : 'max-w-[196px]'}`}
                >
                  <p className="uppercase text-purple font-light text-sm">{`seat`}</p>
                  <p className="text-purple text-sm font-semibold">
                    {order.tickets[carouselCurrentIndex].seat_name.replace(
                      /\D/g,
                      '',
                    )}
                  </p>
                </div>
              </div>

              <Carousel
                className={`w-full p-0 ${(order.tickets.length < 2 || manageTicket) && 'pointer-events-none'}`}
              >
                <CarouselContent
                  className="m-0"
                  isQRCode={booking.config?.is_qr}
                  setCarouselCurrentIndex={setCarouselCurrentIndex}
                >
                  {order.tickets.map((ticket) => {
                    return (
                      <CarouselItem
                        className={`flex flex-col w-full justify-center items-center basis-full pl-0`}
                        key={ticket.uid}
                      >
                        <div
                          className={`flex flex-col items-center ${order.state.upgrade.code === 3 && !ticket.barcode ? 'gap-1 justify-center pt-2' : ' w-[100%] h-auto'}`}
                        >
                          <div
                            className={`flex items-center ${order.state.upgrade.code === 3 && !ticket.barcode ? 'relative rounded-[20px] border-[4px] border-purple3 p-1' : ''}`}
                          >
                            <div
                              className={`flex flex-col items-center w-full ${order.state.upgrade.code === 3 && !ticket.barcode ? 'blur-[3px]' : ''} [&>svg]:w-full`}
                            >
                              {order.config?.is_qr ? (
                                <QRCodeSVG
                                  value={ticket.barcode ?? ''}
                                  size={196}
                                />
                              ) : (
                                <Barcode
                                  value={ticket.barcode ?? 'undefined'}
                                  width={3}
                                />
                              )}
                            </div>
                            {order.state.upgrade.code === 3 &&
                              !ticket.barcode && (
                                <span className="absolute bg-purple3 py-4 left-[-1px] w-[101%] text-sm">
                                  <h4 className="text-center text-balance px-4">
                                    {`${order.config?.is_qr ? 'QR code' : 'Barcode'} will be released before the show`}
                                  </h4>
                                </span>
                              )}
                          </div>
                        </div>
                      </CarouselItem>
                    )
                  })}
                </CarouselContent>
              </Carousel>
            </div>
          </div>

          {navigator.onLine && (
            /*        bookingTickets.state.upgrade.code === 3 && ( */
            <div className="w-full h-[80px] flex justify-center z-50 mt-1">
              <ManageTicket
                setManageTicket={setManageTicket}
                manageTicket={manageTicket}
              />
            </div>
          )}
        </div>
        <div className="bg-purple6 absolute flex flex-col items-center justify-around w-full h-[25%] bottom-0 rounded-[0_0_35px_35px]">
          <div
            className={`w-full h-full flex justify-center border-t border-purple2 border-dashed`}
          >
            <div
              className={`bg-purple w-[28px] h-[28px] rotate-180 rounded-l-full absolute left-[-14px] top-[-15px]`}
            >
              <div
                className={`rounded-l-full w-[14px] h-full absolute left-0 top-0`}
                style={{
                  boxShadow:
                    order.state.upgrade.code === 3
                      ? `inset 4px 0 4px rgba(255, 255, 0, 0.4)`
                      : `inset 4px 0 4px rgba(205, 195, 251, 0.4)`,
                }}
              ></div>
            </div>
            <div
              className={`bg-purple w-[28px] h-[28px] rounded-l-full absolute right-[-12px] top-[-15px]`}
            >
              <div
                className={`rounded-l-full w-[16px] h-full absolute left-0 top-0`}
                style={{
                  boxShadow:
                    order.state.upgrade.code === 3
                      ? `inset 4px 0 4px rgba(255, 255, 0, 0.4)`
                      : `inset 4px 0 4px rgba(205, 195, 251, 0.4)`,
                }}
              ></div>
            </div>
            <div
              className={`flex flex-col gap-2 h-full items-center justify-center m-0`}
            >
              <TicketInfo ticketInfo={booking} upgraded embeddedInTicket />

              {navigator.onLine &&
                (order.state.upgrade.code !== 0 ? (
                  <TicketButton
                    ticketStateCode={order.state.upgrade.code as number}
                    ticketStateMsg={order.state.upgrade.msg}
                    onClick={() => {
                      if (order.state.upgrade.code === 1) {
                        getSeatMap(order)
                      }
                    }}
                  />
                ) : null)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
